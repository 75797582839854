<template>
  <div id="home">
    <van-nav-bar
        title="个人信息"
        :left-arrow="true"
        @click-left="goback('/ucenter')"
        fixed
    />
    <div class="login profile">
      <van-form validate-first @failed="onFailed" @submit="onSubmit">
        <van-field name="avatar" label="会员头像">
          <template #input>
            <van-uploader
                :max-count="1"
                :before-read="beforeRead"
                :after-read="afterRead">
              <van-image :src="userform.avatar"></van-image>
            </van-uploader>
          </template>
        </van-field>
        <van-field
            label="会员账号"
            readonly
            v-model="userform.account"
            name="account"
            placeholder="请输入会员姓名"
        />
        <van-field
            label="会员姓名"
            v-model="userform.name"
            name="name"
            autocomplete="off"
            placeholder="请输入会员姓名"
        />
        <!--<van-field
            readonly
            clickable
            name="birthday"
            v-model="userform.birthday"
            label="出生日期"
            placeholder="请选择出生日期"
            @click="showPicker = true"
        />-->
        <van-field
            label="身份证号码"
            v-model="userform.id_card"
            name="id_card"
            autocomplete="off"
            placeholder="请输入身份证号"
        />
        <van-field
            label="居住地址"
            v-model="userform.address"
            name="address"
            autocomplete="off"
            placeholder="请输入居住地址"
        />
        <div style="margin: 16px;">
          <van-button round block type="info" :loading="loading" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </div>
    <van-popup v-model:show="showPicker" position="bottom" style="width: 100%;">
      <van-datetime-picker
          type="date"
          @confirm="onConfirm"
          @cancel="showPicker = false"
      />
    </van-popup>
  </div>
</template>
<script>
// @ is an alias to /src
import {onMounted, reactive, toRefs} from 'vue';
import {httpget, httpput,httpfile} from "@/libs/api";
import {Toast} from "vant";

export default {
  name: 'Home',
  setup() {
    const state = reactive({
      avatar: require('@/assets/default/imgs/avatar.png'),
      showPicker:false,
      loading:false,
      isuploading: false,
      value: '',
      userform:{
        account:'',
        avatar:'',
        name:'',
        birthday:'',
        id_card:'',
        address:''
      },
    })
    const beforeRead = (file) => {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        Toast("文件格式错误");
        return false;
      }
      return true;
    }
    const dataURLtoFile = (dataurl, filename) => { // 将base64转换为file文件
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {type: mime})
    }
    const afterRead = async (file) => {
      if (file.file.size > 2097152) {
        let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
        let context = canvas.getContext('2d')
        let img = new Image()
        img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
        img.onload = () => {
          canvas.width = 400
          canvas.height = 400
          context.drawImage(img, 0, 0, 400, 400)
          file.content = canvas.toDataURL(file.file.type, 0.90) // 0.92为默认压缩质量
          let files = dataURLtoFile(file.content, file.file.name)
          uploadpic(true,files);
        }
      } else { //小于10M直接上传
        uploadpic(false,file);
      }
    }
    const uploadpic = async (iscp,file) => {
      let fileData = new FormData()
      if(iscp){
        fileData.append('file', file);
      }else{
        fileData.append('file', file.file);
      }
      state.isuploading = true;
      let res = await httpfile('/api/publicapi/upload',fileData);
      if(res.code==0){
        state.userform.avatar = process.env.VUE_APP_IMG_DOMAIN + '/storage/' + res.data.files[0];
        state.isuploading = false;
      }else{
        Toast.fail(res.msg);
        state.isuploading = false;
      }
    }
    const users = async () =>{
      let res = await httpget('/api/member/getinfo');
      if( res.code == 0 ){
        state.userform = res.data;
        console.log(state.userform);
      } else {
        Toast.fail(res.msg);
      }
    }
    const formatter = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const onSubmit = async(values) => {
      state.loading = true;
      let res = await httpput('/api/member/update',values);
      if (res.code === 0) {
        state.loading = false;
        Toast.success(res.msg);
        return;
      } else {
        state.loading = false;
        Toast.success(res.msg);
        return;
      }
    }
    const onFailed = (errorInfo) =>{
      console.log('failed', errorInfo)
    }
    const onConfirm = (date) => {
      state.userform.birthday = formatter(date);
      state.showPicker = false;
    }
    onMounted(() => {
      users();
    })

    return {
      ...toRefs(state),
      users,
      beforeRead,
      dataURLtoFile,
      afterRead,
      onSubmit,
      onFailed,
      formatter,
      onConfirm
    };
  },
}
</script>
